@import "scss/variables";

.home :global {
  .home-banner {
    height: 500px;
    width: 100%;
    background-image: url("/home-banner.jpg");
    background-size: cover;
    background-position: center;
    @media (max-width: 767px) {
      height: 300px;
      background-position: calc(50% - 30px) 50%;
    }
  }
}

.about :global {
  padding: 65px 0;


  @media (max-width: 767px) {
    padding: 20px 0;
  }

  .about-container {
    gap: 32px;


    @media (max-width: 992px) {
      display: flex !important;
      flex-direction: column;
      align-items: center !important;
    }
  }

  .about-image {
    max-width: 420px;
    padding: 64px;
    grid-row: 1;

    @media (max-width: 992px) {
      padding: 20px;
      width: 100%;
    }

    @media (max-width: 767px) {
      max-width: 250px;
    }
  }

  .grey-bg {
    background-color: #DBDAD9E5;
    border-radius: 6px;
  }

  .about-text {
    padding: 64px;
    font-size: 17px;
    grid-row: 1;
    @media (max-width: 767px) {
      font-size: 14px;
      padding: 20px;
    }
  }

}
